var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "260px" },
                      attrs: {
                        options: _vm.codes.clients,
                        placeholder: "제약사",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "clientId", $$v)
                        },
                        expression: "query.clientId",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "모아보기",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isMoaView,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isMoaView", $$v)
                        },
                        expression: "query.isMoaView",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "처방 팝업 알림",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isInfoPopUp,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isInfoPopUp", $$v)
                        },
                        expression: "query.isInfoPopUp",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "라이브DI",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isLiveDi,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isLiveDi", $$v)
                        },
                        expression: "query.isLiveDi",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.postStatusType,
                        reduce: function (option) {
                          return option.code
                        },
                        placeholder: "게시상태",
                      },
                      model: {
                        value: _vm.query.liveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "liveType", $$v)
                        },
                        expression: "query.liveType",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder1",
                        placeholder: "게시기간(Fr)",
                      },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "mr-25" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder2",
                        placeholder: "게시기간(To)",
                      },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      staticStyle: { width: "60%" },
                      attrs: { placeholder: "제목" },
                      model: {
                        value: _vm.query.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "title", $$v)
                        },
                        expression: "query.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 라이브인포 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "liveInfoId",
              items: _vm.drugInfoList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: { "row-selected": _vm.onRowSelectedDrugInfo },
            scopedSlots: _vm._u([
              {
                key: "cell(liveInfoId)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.value) + " ")]
                },
              },
              {
                key: "cell(clientName)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(isMoaView)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(" " + _vm._s(data.value ? "O" : "X") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(isInfoPopUp)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(" " + _vm._s(data.value ? "O" : "X") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(isLiveDi)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(" " + _vm._s(data.value ? "O" : "X") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(liveDate)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c("b-card", { attrs: { "no-body": "" } }, [
            _c(
              "div",
              { staticClass: "m-2" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "EditIcon", size: "19" },
                        }),
                        _c("h4", { staticClass: "mb-0 ml-50" }, [
                          _vm._v(" 상세 "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.resetDrugInfoData()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "PlusSquareIcon" },
                            }),
                            _c("span", [_vm._v("신규 등록")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              disabled: _vm.isProcessing,
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveDrugInfo()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "SaveIcon" },
                            }),
                            _c("span", [_vm._v("저장")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: { variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeDrugInfo()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "Trash2Icon" },
                            }),
                            _c("span", [_vm._v("삭제")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { rules: "required" } },
                  [
                    _c("b-form-radio-group", {
                      attrs: {
                        id: "liveinfo-is-drugInfo-type",
                        options: _vm.codes.drugInfoType,
                        "value-field": "code",
                        "text-field": "label",
                      },
                      on: { change: _vm.drugInfoTypeChange },
                      model: {
                        value: _vm.drugInfoData.drugInfoType,
                        callback: function ($$v) {
                          _vm.$set(_vm.drugInfoData, "drugInfoType", $$v)
                        },
                        expression: "drugInfoData.drugInfoType",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "제목",
                              "label-for": "liveinfo-title",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "제목", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "title",
                                          placeholder: "입력하세요",
                                          state:
                                            errors.length > 0 ? false : null,
                                        },
                                        model: {
                                          value: _vm.drugInfoData.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drugInfoData,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "drugInfoData.title",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "사용여부",
                              "label-for": "liveinfo-is-use",
                              rules: "required",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: {
                                  "value-field": "code",
                                  "text-field": "label",
                                },
                                model: {
                                  value: _vm.drugInfoData.isUse,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.drugInfoData, "isUse", $$v)
                                  },
                                  expression: "drugInfoData.isUse",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "제약사",
                              "label-for": "druginfo-client-id",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "제약사", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("v-select", {
                                        attrs: {
                                          id: "druginfo-client-id",
                                          options: _vm.codes.clients,
                                          placeholder: "선택하세요",
                                          reduce: function (option) {
                                            return option.code
                                          },
                                          state:
                                            errors.length > 0 ? false : null,
                                        },
                                        model: {
                                          value: _vm.drugInfoData.clientId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drugInfoData,
                                              "clientId",
                                              $$v
                                            )
                                          },
                                          expression: "drugInfoData.clientId",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "제품명",
                              "label-for": "liveinfo-brand-name",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "liveinfo-brand-name",
                                placeholder: "입력하세요",
                                maxlength: "30",
                              },
                              model: {
                                value: _vm.drugInfoData.brandName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.drugInfoData, "brandName", $$v)
                                },
                                expression: "drugInfoData.brandName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.drugInfoData.drugInfoType == 1,
                        expression: "drugInfoData.drugInfoType == 1",
                      },
                    ],
                  },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "컨텐츠",
                              "label-for": "druginfo-content",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "contentFile",
                              attrs: {
                                accept: "text/plain,.html",
                                placeholder: "HTML 파일을 입력하세요.",
                              },
                              on: { input: _vm.readContentFile },
                            }),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              model: {
                                value: _vm.drugInfoData.htmlContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.drugInfoData, "htmlContent", $$v)
                                },
                                expression: "drugInfoData.htmlContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-sm-50",
                            staticStyle: { "margin-top": "21px" },
                            attrs: {
                              variant: "primary",
                              disabled: _vm.drugInfoData.htmlContent === null,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          },
                          [_c("span", [_vm._v("미리보기")])]
                        ),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "drugInfoPreview",
                              title: "HTML 미리보기",
                            },
                            model: {
                              value: _vm.showModal,
                              callback: function ($$v) {
                                _vm.showModal = $$v
                              },
                              expression: "showModal",
                            },
                          },
                          [
                            _c("iframe", {
                              ref: "previewFrame",
                              staticStyle: { "background-color": "white" },
                              attrs: {
                                srcdoc: _vm.drugInfoData.htmlContent,
                                frameborder: "0",
                                "max-width": "1150px",
                                width: "1115px",
                                height: "1000px",
                              },
                            }),
                          ]
                        ),
                        _vm.drugInfoData.liveInfoId &&
                        _vm.drugInfoData.htmlContent != null
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mr-sm-50",
                                staticStyle: { "margin-top": "21px" },
                                attrs: { variant: "outline-dark" },
                                on: { click: _vm.downloadContent },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "DownloadIcon" },
                                }),
                                _c("span", [_vm._v("다운로드")]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.drugInfoData.drugInfoType == 2
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "외부 링크 연결",
                                  "label-for": "druginfo-external-link",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "외부 링크 연결",
                                    rules: "url",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "title",
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                                placeholder: "URL을 입력하세요",
                                              },
                                              model: {
                                                value:
                                                  _vm.drugInfoData.externalLink,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.drugInfoData,
                                                    "externalLink",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "drugInfoData.externalLink",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3154143514
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "닥터인포 노출 여부",
                                  "label-for": "druginfo-is-drinfo-open",
                                  rules: "required",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio-group",
                                  {
                                    model: {
                                      value: _vm.drugInfoData.isDrInfoOpen,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.drugInfoData,
                                          "isDrInfoOpen",
                                          $$v
                                        )
                                      },
                                      expression: "drugInfoData.isDrInfoOpen",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass: "custom-control-primary",
                                        attrs: { value: true },
                                      },
                                      [_vm._v(" 노출 ")]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass: "custom-control-secondary",
                                        attrs: { value: false },
                                      },
                                      [_vm._v(" 노출 안함 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.drugInfoData.drugInfoType == 2,
                        expression: "drugInfoData.drugInfoType == 2",
                      },
                    ],
                  },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "상세 탭 1",
                              "label-for": "druginfo-tab-1",
                            },
                          },
                          [
                            (_vm.tabInfoData.tabHtml1 === null ||
                              _vm.tabInfoData.tabHtml1.length === 0) &&
                            (_vm.tabInfoData.tabUrl1 === null ||
                              _vm.tabInfoData.tabUrl1.length === 0)
                              ? _c("b-form-input", {
                                  attrs: {
                                    placeholder: "상세 탭1 내용을 입력하세요",
                                    disabled: "",
                                  },
                                })
                              : _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "상세 탭1 내용이 입력되었습니다.",
                                    disabled: "",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-sm-50",
                            staticStyle: {
                              "margin-top": "22px",
                              width: "93px",
                            },
                            attrs: { variant: "outline-primary" },
                            on: { click: _vm.openEditorModal1 },
                          },
                          [_c("span", [_vm._v("등록")])]
                        ),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              size: "lg",
                              title: "등록",
                              "ok-title": "적용",
                              "cancel-title": "취소",
                              "no-fade": "",
                              "no-enforce-focus": "",
                            },
                            on: {
                              ok: function ($event) {
                                $event.preventDefault()
                                return _vm.tabSubmit1()
                              },
                            },
                            model: {
                              value: _vm.editorModal1,
                              callback: function ($$v) {
                                _vm.editorModal1 = $$v
                              },
                              expression: "editorModal1",
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "구분",
                                  "label-for": "druginfo-tab-type1",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio-group",
                                  {
                                    model: {
                                      value: _vm.tabInfoData.tabType1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tabInfoData,
                                          "tabType1",
                                          $$v
                                        )
                                      },
                                      expression: "tabInfoData.tabType1",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass: "custom-control-primary",
                                        attrs: { value: 1 },
                                      },
                                      [_vm._v(" 자체 작성 내용 ")]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass: "custom-control-primary",
                                        attrs: { value: 2 },
                                      },
                                      [_vm._v(" 외부 링크 연결 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "상세 탭1 제목",
                                      "label-for": "druginfo-tab-title1",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.tabInfoData.tabTitle1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tabInfoData,
                                            "tabTitle1",
                                            $$v
                                          )
                                        },
                                        expression: "tabInfoData.tabTitle1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.tabInfoData.tabType1 === 1
                                  ? _c(
                                      "div",
                                      [
                                        _vm.editorModal1
                                          ? _c("tinymce-editor", {
                                              model: {
                                                value: _vm.tabInfoData.tabHtml1,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tabInfoData,
                                                    "tabHtml1",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tabInfoData.tabHtml1",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm.tabInfoData.tabType1 === 2
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                "URL 입력 (※ 예시 : https://www.ubcare.co.kr)",
                                              "label-for": "druninfo-tab-url1",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "URL 입력",
                                                rules: "url",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                          placeholder:
                                                            "URL을 입력하세요",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tabInfoData
                                                              .tabUrl1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tabInfoData,
                                                              "tabUrl1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tabInfoData.tabUrl1",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.drugInfoData.drugInfoType == 2
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "상세 탭 2",
                                  "label-for": "druginfo-tab-2",
                                },
                              },
                              [
                                _vm.drugInfoData.tabContent2 === null
                                  ? _c("b-form-input", {
                                      attrs: {
                                        placeholder:
                                          "상세 탭2 내용을 입력하세요",
                                        disabled: "",
                                      },
                                    })
                                  : _c("b-form-input", {
                                      attrs: {
                                        placeholder:
                                          "상세 탭2 내용이 입력되었습니다.",
                                        disabled: "",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-sm-50",
                                staticStyle: {
                                  "margin-top": "22px",
                                  width: "93px",
                                },
                                attrs: { variant: "outline-primary" },
                                on: { click: _vm.openEditorModal2 },
                              },
                              [_c("span", [_vm._v("등록")])]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  size: "lg",
                                  title: "등록",
                                  "ok-title": "적용",
                                  "cancel-title": "취소",
                                  "no-fade": "",
                                  "no-enforce-focus": "",
                                },
                                on: {
                                  ok: function ($event) {
                                    $event.preventDefault()
                                    return _vm.tabSubmit2()
                                  },
                                },
                                model: {
                                  value: _vm.editorModal2,
                                  callback: function ($$v) {
                                    _vm.editorModal2 = $$v
                                  },
                                  expression: "editorModal2",
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "구분",
                                      "label-for": "druginfo-tab-type2",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio-group",
                                      {
                                        model: {
                                          value: _vm.tabInfoData.tabType2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tabInfoData,
                                              "tabType2",
                                              $$v
                                            )
                                          },
                                          expression: "tabInfoData.tabType2",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-radio",
                                          {
                                            staticClass:
                                              "custom-control-primary",
                                            attrs: { value: 1 },
                                          },
                                          [_vm._v(" 자체 작성 내용 ")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          {
                                            staticClass:
                                              "custom-control-primary",
                                            attrs: { value: 2 },
                                          },
                                          [_vm._v(" 외부 링크 연결 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "상세 탭2 제목",
                                          "label-for": "druginfo-tab-title2",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.tabInfoData.tabTitle2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tabInfoData,
                                                "tabTitle2",
                                                $$v
                                              )
                                            },
                                            expression: "tabInfoData.tabTitle2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.tabInfoData.tabType2 === 1
                                      ? _c(
                                          "div",
                                          [
                                            _vm.editorModal2
                                              ? _c("tinymce-editor", {
                                                  model: {
                                                    value:
                                                      _vm.tabInfoData.tabHtml2,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tabInfoData,
                                                        "tabHtml2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tabInfoData.tabHtml2",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.tabInfoData.tabType2 === 2
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label:
                                                    "URL 입력 (※ 예시 : https://www.ubcare.co.kr)",
                                                  "label-for":
                                                    "druninfo-tab-url2",
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "URL 입력",
                                                    rules: "url",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                state:
                                                                  errors.length >
                                                                  0
                                                                    ? false
                                                                    : null,
                                                                placeholder:
                                                                  "URL을 입력하세요",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .tabInfoData
                                                                    .tabUrl2,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.tabInfoData,
                                                                      "tabUrl2",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "tabInfoData.tabUrl2",
                                                              },
                                                            }),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    414676609
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.drugInfoData.drugInfoType == 2
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "상세 탭 3",
                                  "label-for": "druginfo-tab-3",
                                },
                              },
                              [
                                _vm.drugInfoData.tabContent3 === null
                                  ? _c("b-form-input", {
                                      attrs: {
                                        placeholder:
                                          "상세 탭3 내용을 입력하세요",
                                        disabled: "",
                                      },
                                    })
                                  : _c("b-form-input", {
                                      attrs: {
                                        placeholder:
                                          "상세 탭3 내용이 입력되었습니다.",
                                        disabled: "",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-sm-50",
                                staticStyle: {
                                  "margin-top": "22px",
                                  width: "93px",
                                },
                                attrs: { variant: "outline-primary" },
                                on: { click: _vm.openEditorModal3 },
                              },
                              [_c("span", [_vm._v("등록")])]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  size: "lg",
                                  title: "등록",
                                  "ok-title": "적용",
                                  "cancel-title": "취소",
                                  "no-fade": "",
                                  "no-enforce-focus": "",
                                },
                                on: {
                                  ok: function ($event) {
                                    $event.preventDefault()
                                    return _vm.tabSubmit3()
                                  },
                                },
                                model: {
                                  value: _vm.editorModal3,
                                  callback: function ($$v) {
                                    _vm.editorModal3 = $$v
                                  },
                                  expression: "editorModal3",
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "구분",
                                      "label-for": "druginfo-tab-type3",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio-group",
                                      {
                                        model: {
                                          value: _vm.tabInfoData.tabType3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tabInfoData,
                                              "tabType3",
                                              $$v
                                            )
                                          },
                                          expression: "tabInfoData.tabType3",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-radio",
                                          {
                                            staticClass:
                                              "custom-control-primary",
                                            attrs: { value: 1 },
                                          },
                                          [_vm._v(" 자체 작성 내용 ")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          {
                                            staticClass:
                                              "custom-control-primary",
                                            attrs: { value: 2 },
                                          },
                                          [_vm._v(" 외부 링크 연결 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "상세 탭3 제목",
                                          "label-for": "druginfo-tab-title3",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.tabInfoData.tabTitle3,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tabInfoData,
                                                "tabTitle3",
                                                $$v
                                              )
                                            },
                                            expression: "tabInfoData.tabTitle3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.tabInfoData.tabType3 === 1
                                      ? _c(
                                          "div",
                                          [
                                            _vm.editorModal3
                                              ? _c("tinymce-editor", {
                                                  model: {
                                                    value:
                                                      _vm.tabInfoData.tabHtml3,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tabInfoData,
                                                        "tabHtml3",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tabInfoData.tabHtml3",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.tabInfoData.tabType3 === 2
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label:
                                                    "URL 입력 (※ 예시 : https://www.ubcare.co.kr)",
                                                  "label-for":
                                                    "druninfo-tab-url3",
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "URL 입력",
                                                    rules: "url",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                state:
                                                                  errors.length >
                                                                  0
                                                                    ? false
                                                                    : null,
                                                                placeholder:
                                                                  "URL을 입력하세요",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .tabInfoData
                                                                    .tabUrl3,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.tabInfoData,
                                                                      "tabUrl3",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "tabInfoData.tabUrl3",
                                                              },
                                                            }),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3090659968
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "검색키워드",
                              "label-for": "druginfo-search-keyword",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "title",
                                placeholder: "키워드를 입력하세요",
                              },
                              model: {
                                value: _vm.drugInfoData.searchKeyWord,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.drugInfoData,
                                    "searchKeyWord",
                                    $$v
                                  )
                                },
                                expression: "drugInfoData.searchKeyWord",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "Main 썸네일 (※ 이미지 사이즈는 1300 * 260 입니다)",
                              "label-for": "main-thumbnail-files",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "mainImgFile",
                              attrs: {
                                accept: "image/*",
                                placeholder: "파일 찾기",
                              },
                              on: { input: _vm.uploadMainImage },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.drugInfoFiles.mainImgFileName) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "Sub 썸네일 (※ 이미지 사이즈는 640 * 140 입니다)",
                              "label-for": "sub-thumbnail-files",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "subImgFile",
                              attrs: {
                                accept: "image/*",
                                placeholder: "파일 찾기",
                              },
                              on: { input: _vm.uploadSubImage },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.drugInfoFiles.subImgFileName) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drugInfoData.drugInfoType == 1,
                                expression: "drugInfoData.drugInfoType == 1",
                              },
                            ],
                            staticClass: "mt-1",
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              label: "방문 신청",
                              "label-for": "druginfo-is-visit-call",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: {
                                  id: "visitOption",
                                  name: "isVisitCall",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.drugInfoData.isVisitCall,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.drugInfoData,
                                      "isVisitCall",
                                      $$v
                                    )
                                  },
                                  expression: "drugInfoData.isVisitCall",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drugInfoData.drugInfoType == 1,
                                expression: "drugInfoData.drugInfoType == 1",
                              },
                            ],
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              label: "샘플 신청",
                              "label-for": "druginfo-is-sample-call",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: {
                                  id: "sampleOption",
                                  name: "isSampleCall",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.drugInfoData.isSampleCall,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.drugInfoData,
                                      "isSampleCall",
                                      $$v
                                    )
                                  },
                                  expression: "drugInfoData.isSampleCall",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drugInfoData.drugInfoType == 1,
                                expression: "drugInfoData.drugInfoType == 1",
                              },
                            ],
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              label: "이메일 답변 신청",
                              "label-for": "druginfo-is-email-answer",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: { id: "emailOption", disabled: "" },
                                model: {
                                  value: _vm.drugInfoData.isEmailAnswer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.drugInfoData,
                                      "isEmailAnswer",
                                      $$v
                                    )
                                  },
                                  expression: "drugInfoData.isEmailAnswer",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "댓글 입력",
                              "label-for": "druginfo-is-comment",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: { name: "isComment" },
                                model: {
                                  value: _vm.drugInfoData.isComment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.drugInfoData, "isComment", $$v)
                                  },
                                  expression: "drugInfoData.isComment",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("live-info-form", {
        attrs: {
          "liveinfo-id": _vm.drugInfoData.liveInfoId,
          "clear-flag": _vm.liveInfoFormClearFlag,
          "valid-flag": _vm.liveInfoFormValidFlag,
          "content-type": 1,
        },
        on: { callback: _vm.liveInfoForm.callback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }