<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:260px;"
            />
            <v-select
              v-model="query.isMoaView"
              :options="codes.useTypeFixed"
              class="invoice-filter-select el-def"
              placeholder="모아보기"
              :reduce="(option) => option.code"
              style="width:200px;"
            />
            <v-select
              v-model="query.isInfoPopUp"
              :options="codes.useTypeFixed"
              class="invoice-filter-select el-def"
              placeholder="처방 팝업 알림"
              :reduce="(option) => option.code"
              style="width:200px;"
            />
            <v-select
              v-model="query.isLiveDi"
              :options="codes.useTypeFixed"
              class="invoice-filter-select el-def"
              placeholder="라이브DI"
              :reduce="(option) => option.code"
              style="width:200px;"
            />
            <v-select
              v-model="query.liveType"
              :options="codes.postStatusType"
              class="invoice-filter-select el-def"
              :reduce="(option) => option.code"
              placeholder="게시상태"
              style="width:200px;"
            />

          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="mr-25">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
            <b-form-input
              v-model="query.title"
              class="el-def"
              placeholder="제목"
              style="width:60%;"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              라이브인포 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="liveInfoId"
        :items="drugInfoList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedDrugInfo"
      >
        <!-- Column: No -->
        <template #cell(liveInfoId)="data">
          {{ data.value }}
        </template>
        <template #cell(clientName)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(isMoaView)="data">
          <div
            class="text-nowrap"
            style="text-align: center;"
          >
            {{ data.value ? 'O' : 'X' }}
          </div>
        </template>
        <template #cell(isInfoPopUp)="data">
          <div
            class="text-nowrap"
            style="text-align: center;"
          >
            {{ data.value ? 'O' : 'X' }}
          </div>
        </template>
        <template #cell(isLiveDi)="data">
          <div
            class="text-nowrap"
            style="text-align: center;"
          >
            {{ data.value ? 'O' : 'X' }}
          </div>
        </template>
        <template #cell(liveDate)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

      </b-table>
    </b-card>

    <validation-observer ref="formRules">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start"
            >
              <feather-icon
                icon="EditIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                상세
              </h4>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="outline-primary"
                @click.prevent="resetDrugInfoData()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-50"
                />
                <span>신규 등록</span>
              </b-button>
              <b-button
                :disabled="isProcessing"
                variant="primary"
                class="ml-1"
                @click.prevent="saveDrugInfo()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span>저장</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
                @click.prevent="removeDrugInfo()"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>삭제</span>
              </b-button>
            </b-col>
          </b-row>
          <b-form-group
            rules="required"
          >
            <b-form-radio-group
              id="liveinfo-is-drugInfo-type"
              v-model="drugInfoData.drugInfoType"
              :options="codes.drugInfoType"
              value-field="code"
              text-field="label"
              @change="drugInfoTypeChange"
            />
          </b-form-group>

          <!-- Edit Form -->

          <b-row class="mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="제목"
                label-for="liveinfo-title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="제목"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="drugInfoData.title"
                    placeholder="입력하세요"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="사용여부"
                label-for="liveinfo-is-use"
                rules="required"
              >
                <b-form-radio-group
                  v-model="drugInfoData.isUse"
                  value-field="code"
                  text-field="label"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="제약사"
                label-for="druginfo-client-id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="제약사"
                  rules="required"
                >
                  <v-select
                    id="druginfo-client-id"
                    v-model="drugInfoData.clientId"
                    :options="codes.clients"
                    placeholder="선택하세요"
                    :reduce="option => option.code"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="제품명"
                label-for="liveinfo-brand-name"
              >
                <b-form-input
                  id="liveinfo-brand-name"
                  v-model="drugInfoData.brandName"
                  placeholder="입력하세요"
                  maxlength="30"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="drugInfoData.drugInfoType == 1">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="컨텐츠"
                label-for="druginfo-content"
              >
                <b-form-file
                  ref="contentFile"
                  accept="text/plain,.html"
                  placeholder="HTML 파일을 입력하세요."
                  @input="readContentFile"
                />
                <b-form-input
                  v-show="false"
                  v-model="drugInfoData.htmlContent"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                class="mr-sm-50"
                style="margin-top: 21px"
                :disabled="drugInfoData.htmlContent === null"
                @click="showModal = true"
              >
                <span>미리보기</span>
              </b-button>
              <b-modal
                id="drugInfoPreview"
                v-model="showModal"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="drugInfoData.htmlContent"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>

              <b-button
                v-if="drugInfoData.liveInfoId && drugInfoData.htmlContent != null"
                variant="outline-dark"
                class="mr-sm-50"
                style="margin-top: 21px"
                @click="downloadContent"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span>다운로드</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- 상세보기 목록 -->
          <b-row v-if="drugInfoData.drugInfoType == 2">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="외부 링크 연결"
                label-for="druginfo-external-link"
              >
                <validation-provider
                  #default="{ errors }"
                  name="외부 링크 연결"
                  rules="url"
                >
                  <b-form-input
                    id="title"
                    v-model="drugInfoData.externalLink"
                    :state="errors.length > 0 ? false : null"
                    placeholder="URL을 입력하세요"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="닥터인포 노출 여부"
                label-for="druginfo-is-drinfo-open"
                rules="required"
              >
                <b-form-radio-group
                  v-model="drugInfoData.isDrInfoOpen"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    :value="true"
                  >
                    노출
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    :value="false"
                  >
                    노출 안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="drugInfoData.drugInfoType == 2">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="상세 탭 1"
                label-for="druginfo-tab-1"
              >
                <b-form-input
                  v-if="(tabInfoData.tabHtml1 === null || tabInfoData.tabHtml1.length === 0) && (tabInfoData.tabUrl1 === null || tabInfoData.tabUrl1.length === 0)"
                  placeholder="상세 탭1 내용을 입력하세요"
                  disabled
                />
                <b-form-input
                  v-else
                  placeholder="상세 탭1 내용이 입력되었습니다."
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
                class="mr-sm-50"
                style="margin-top: 22px; width: 93px;"
                @click="openEditorModal1"
              >
                <span>등록</span>
              </b-button>
              <b-modal
                v-model="editorModal1"
                size="lg"
                title="등록"
                ok-title="적용"
                cancel-title="취소"
                no-fade
                no-enforce-focus
                @ok.prevent="tabSubmit1()"
              >
                <b-form-group
                  label="구분"
                  label-for="druginfo-tab-type1"
                >
                  <b-form-radio-group
                    v-model="tabInfoData.tabType1"
                  >
                    <b-form-radio
                      class="custom-control-primary"
                      :value="1"
                    >
                      자체 작성 내용
                    </b-form-radio>
                    <b-form-radio
                      class="custom-control-primary"
                      :value="2"
                    >
                      외부 링크 연결
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <div>
                  <b-form-group
                    label="상세 탭1 제목"
                    label-for="druginfo-tab-title1"
                  >
                    <b-form-input
                      v-model="tabInfoData.tabTitle1"
                    />
                  </b-form-group>
                  <div v-if="tabInfoData.tabType1 === 1">
                    <tinymce-editor
                      v-if="editorModal1"
                      v-model="tabInfoData.tabHtml1"
                    />
                  </div>

                  <div v-else-if="tabInfoData.tabType1 === 2">
                    <b-form-group
                      label="URL 입력 (※ 예시 : https://www.ubcare.co.kr)"
                      label-for="druninfo-tab-url1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="URL 입력"
                        rules="url"
                      >
                        <b-form-input
                          v-model="tabInfoData.tabUrl1"
                          :state="errors.length > 0 ? false : null"
                          placeholder="URL을 입력하세요"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-modal>
            </b-col>
          </b-row>

          <b-row v-if="drugInfoData.drugInfoType == 2">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="상세 탭 2"
                label-for="druginfo-tab-2"
              >
                <b-form-input
                  v-if="drugInfoData.tabContent2 === null"
                  placeholder="상세 탭2 내용을 입력하세요"
                  disabled
                />
                <b-form-input
                  v-else
                  placeholder="상세 탭2 내용이 입력되었습니다."
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
                class="mr-sm-50"
                style="margin-top: 22px; width: 93px;"
                @click="openEditorModal2"
              >
                <span>등록</span>
              </b-button>
              <!-- <b-button
                variant="primary"
                class="mr-sm-50"
                style="margin-top: 22px"
                @click="detailTabView2 = true"
              >
                <span>미리보기</span>
              </b-button>
              <b-modal
                v-model="detailTabView2"
                size="lg"
                title="미리보기"
              >
                <iframe
                  ref="previewTabFrame1"
                  :srcdoc="drugInfoData.tabContent2"
                  frameborder="0"
                  width="1906px"
                  height="800px"
                />
              </b-modal> -->
              <b-modal
                v-model="editorModal2"
                size="lg"
                title="등록"
                ok-title="적용"
                cancel-title="취소"
                no-fade
                no-enforce-focus
                @ok.prevent="tabSubmit2()"
              >
                <b-form-group
                  label="구분"
                  label-for="druginfo-tab-type2"
                >
                  <b-form-radio-group
                    v-model="tabInfoData.tabType2"
                  >
                    <b-form-radio
                      class="custom-control-primary"
                      :value="1"
                    >
                      자체 작성 내용
                    </b-form-radio>
                    <b-form-radio
                      class="custom-control-primary"
                      :value="2"
                    >
                      외부 링크 연결
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <div>
                  <b-form-group
                    label="상세 탭2 제목"
                    label-for="druginfo-tab-title2"
                  >
                    <b-form-input
                      v-model="tabInfoData.tabTitle2"
                    />
                  </b-form-group>
                  <div v-if="tabInfoData.tabType2 === 1">
                    <tinymce-editor
                      v-if="editorModal2"
                      v-model="tabInfoData.tabHtml2"
                    />
                  </div>

                  <div v-else-if="tabInfoData.tabType2 === 2">
                    <b-form-group
                      label="URL 입력 (※ 예시 : https://www.ubcare.co.kr)"
                      label-for="druninfo-tab-url2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="URL 입력"
                        rules="url"
                      >
                        <b-form-input
                          v-model="tabInfoData.tabUrl2"
                          :state="errors.length > 0 ? false : null"
                          placeholder="URL을 입력하세요"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-modal>
            </b-col>
          </b-row>

          <b-row v-if="drugInfoData.drugInfoType == 2">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="상세 탭 3"
                label-for="druginfo-tab-3"
              >
                <b-form-input
                  v-if="drugInfoData.tabContent3 === null"
                  placeholder="상세 탭3 내용을 입력하세요"
                  disabled
                />
                <b-form-input
                  v-else
                  placeholder="상세 탭3 내용이 입력되었습니다."
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
                class="mr-sm-50"
                style="margin-top: 22px; width: 93px;"
                @click="openEditorModal3"
              >
                <span>등록</span>
              </b-button>
              <b-modal
                v-model="editorModal3"
                size="lg"
                title="등록"
                ok-title="적용"
                cancel-title="취소"
                no-fade
                no-enforce-focus
                @ok.prevent="tabSubmit3()"
              >
                <b-form-group
                  label="구분"
                  label-for="druginfo-tab-type3"
                >
                  <b-form-radio-group
                    v-model="tabInfoData.tabType3"
                  >
                    <b-form-radio
                      class="custom-control-primary"
                      :value="1"
                    >
                      자체 작성 내용
                    </b-form-radio>
                    <b-form-radio
                      class="custom-control-primary"
                      :value="2"
                    >
                      외부 링크 연결
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <div>
                  <b-form-group
                    label="상세 탭3 제목"
                    label-for="druginfo-tab-title3"
                  >
                    <b-form-input
                      v-model="tabInfoData.tabTitle3"
                    />
                  </b-form-group>
                  <div v-if="tabInfoData.tabType3 === 1">
                    <tinymce-editor
                      v-if="editorModal3"
                      v-model="tabInfoData.tabHtml3"
                    />
                  </div>

                  <div v-else-if="tabInfoData.tabType3 === 2">
                    <b-form-group
                      label="URL 입력 (※ 예시 : https://www.ubcare.co.kr)"
                      label-for="druninfo-tab-url3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="URL 입력"
                        rules="url"
                      >
                        <b-form-input
                          v-model="tabInfoData.tabUrl3"
                          :state="errors.length > 0 ? false : null"
                          placeholder="URL을 입력하세요"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-modal>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="검색키워드"
                label-for="druginfo-search-keyword"
              >
                <b-form-input
                  id="title"
                  v-model="drugInfoData.searchKeyWord"
                  placeholder="키워드를 입력하세요"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Main 썸네일 (※ 이미지 사이즈는 1300 * 260 입니다)"
                label-for="main-thumbnail-files"
              >
                <b-form-file
                  ref="mainImgFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="uploadMainImage"
                />
                <p>
                  {{ drugInfoFiles.mainImgFileName }}
                </p>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Sub 썸네일 (※ 이미지 사이즈는 640 * 140 입니다)"
                label-for="sub-thumbnail-files"
              >
                <b-form-file
                  ref="subImgFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="uploadSubImage"
                />
                <p>
                  {{ drugInfoFiles.subImgFileName }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                v-show="drugInfoData.drugInfoType == 1"
                label="방문 신청"
                class="mt-1"
                label-for="druginfo-is-visit-call"
                style="margin-right: 50px"
              >
                <b-form-radio-group
                  id="visitOption"
                  v-model="drugInfoData.isVisitCall"
                  name="isVisitCall"
                  disabled
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                v-show="drugInfoData.drugInfoType == 1"
                label="샘플 신청"
                label-for="druginfo-is-sample-call"
                style="margin-right: 50px"
              >
                <b-form-radio-group
                  id="sampleOption"
                  v-model="drugInfoData.isSampleCall"
                  name="isSampleCall"
                  disabled
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-show="drugInfoData.drugInfoType == 1"
                label="이메일 답변 신청"
                label-for="druginfo-is-email-answer"
                style="margin-right: 50px"
              >
                <b-form-radio-group
                  id="emailOption"
                  v-model="drugInfoData.isEmailAnswer"
                  disabled
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                label="댓글 입력"
                label-for="druginfo-is-comment"
              >
                <b-form-radio-group
                  v-model="drugInfoData.isComment"
                  name="isComment"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </validation-observer>

    <live-info-form
      :liveinfo-id="drugInfoData.liveInfoId"
      :clear-flag="liveInfoFormClearFlag"
      :valid-flag="liveInfoFormValidFlag"
      :content-type="1"
      @callback="liveInfoForm.callback"
    />
  </div>
</template>

<script>
import axios from "@axios"
import dayjs from "dayjs"
import {
  ref, onMounted, getCurrentInstance,
} from "@vue/composition-api"
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import vSelect from "vue-select"
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { BFormFile } from "bootstrap-vue"
import { errorFormatter } from '@core/utils/filter'
import TinymceEditor from '@/views/TinyMCE.vue'
import LiveInfoForm from '../LiveInfoForm.vue'

export default {
  components: {
    vSelect,
    LiveInfoForm,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    TinymceEditor,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    extend('url', {
      validate: value => {
        const pattern = /^(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

        if (pattern.test(value) === false) return '유효한 URL 형식이 아닙니다.'
        return true
      },
    })

    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const contentsFile = ref(null)
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Main Table Config
    const drugInfoInitState = {
      liveInfoId: null,
      title: null,
      brandName: null,
      isVisitCall: false,
      isSampleCall: false,
      isEmailAnswer: false,
      clientId: null,
      mainImgFileId: null,
      subImgFileId: null,
      isComment: false,
      searchKeyWord: null,
      isUse: true,
      htmlContent: null,
      liveInfo: {
        liveInfoId: null,
        schedules: [],
        contentType: 1, // 약품정보
        isTopFix: false,
        topFixLevel: 0,
        minAge: 0,
        maxAge: 120,
      },
      drugInfoType: 2, // html 입력 타입
      isTopFixedAllow: false,
      selectContentFile: null,
      isDrInfoOpen: true,
      externalLink: null,
      tabType1: null,
      tabType2: null,
      tabType3: null,
      tabContent1: null,
      tabContent2: null,
      tabContent3: null,
      tabTitle1: null,
      tabTitle2: null,
      tabTitle3: null,
    }

    const drugInfoData = ref({ ...drugInfoInitState })

    const drugInfoFileInitState = {
      mainImgFile: null,
      mainImgFileId: 0,
      mainImgFileSrc: "",
      mainImgFileName: "",
      subImgFile: null,
      subImgFileId: 0,
      subImgFileSrc: "",
      subImgFileName: "",
    }

    const tabInitState = {
      tabType1: 1,
      tabTitle1: null,
      tabHtml1: null,
      tabUrl1: null,
      tabType2: 1,
      tabTitle2: null,
      tabHtml2: null,
      tabUrl2: null,
      tabType3: 1,
      tabTitle3: null,
      tabHtml3: null,
      tabUrl3: null,
    }

    const tabInfoData = ref({ ...tabInitState })

    const drugInfoFiles = ref({ ...drugInfoFileInitState })

    const resetDrugInfoData = () => {
      contentFile.value.reset()
      mainImgFile.value.reset()
      subImgFile.value.reset()
      Object.assign(drugInfoData.value, drugInfoInitState)
      Object.assign(drugInfoFiles.value, drugInfoFileInitState)
      Object.assign(tabInfoData.value, tabInitState)

      refs.formRules.reset()

      liveInfoFormClearFlag.value = !liveInfoFormClearFlag.value
    }

    // Set Codes
    const codes = ref({
      useTypeFixed: [],
      isLive: [],
      clients: [],
      drugInfoType: [],
    })

    const fetchCodes = () => {
      axios.get("/fa/druginfo/codes")
        .then(rs => {
          const {
            useTypeFixed, postStatusType, drugInfoType, clients,
          } = rs.data

          codes.value.useTypeFixed = useTypeFixed
          codes.value.postStatusType = postStatusType
          codes.value.drugInfoType = drugInfoType
          codes.value.clients = clients

          refetchData()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      liveInfoId: null,
      clientId: null,
      startDate: null,
      endDate: null,
      isMoaView: null,
      isInfoPopUp: null,
      isLiveDi: null,
      postStatusType: null,
      title: null,
    })

    const isProcessing = ref(false)
    const drugInfoList = ref([])
    const totalRecords = ref(0)
    const dateFormatter = value => dayjs(new Date(value)).format("YY-MM-DD")
    const liveInfoFormValidFlag = ref(false)
    const liveInfoFormClearFlag = ref(false)
    const contentFile = ref(null)
    const mainImgFile = ref(null)
    const subImgFile = ref(null)
    const showModal = ref(false)

    // 탭방식 변수
    const detailTabView1 = ref(false)
    const editorModal1 = ref(false)
    const detailTabView2 = ref(false)
    const editorModal2 = ref(false)
    const detailTabView3 = ref(false)
    const editorModal3 = ref(false)

    const liveInfoForm = {
      callback: rs => {
        if (!rs) return

        const drugInfo = drugInfoData.value
        const liveInfo = rs

        liveInfo.title = drugInfo.title
        liveInfo.brandName = drugInfo.brandName
        liveInfo.isUse = drugInfo.isUse
        liveInfo.contentType = 1
        liveInfo.htmlContent = drugInfo.htmlContent

        drugInfoData.value.liveInfo = liveInfo

        if (drugInfoData.value.drugInfoType === 1 && drugInfoData.value.htmlContent === null) {
          pushToast('warning', '컨텐츠를 등록하세요.')
          return
        }

        if (drugInfoData.value.drugInfoType === 2) {
          if (
            (drugInfoData.value.externalLink === null || drugInfoData.value.externalLink.length === 0)
            && (drugInfoData.value.tabContent1 === null || drugInfoData.value.tabContent1.length === 0)
            && (drugInfoData.value.tabContent2 === null || drugInfoData.value.tabContent2.length === 0)
            && (drugInfoData.value.tabContent3 === null || drugInfoData.value.tabContent3.length === 0)
          ) {
            pushToast('warning', '외부링크 또는 상세 탭 중 하나 이상의 컨텐츠는(은) 필수 입력입니다.')
            return
          }

          if (drugInfoData.value.externalLink === null || drugInfoData.value.externalLink.length === 0) {
            if (drugInfoData.value.tabContent1 != null && (drugInfoData.value.tabTitle1 === null || drugInfoData.value.tabTitle1.length === 0)) {
              pushToast('warning', '상세 탭1 제목을 입력하세요.')
              return
            }

            if (drugInfoData.value.tabContent2 != null && (drugInfoData.value.tabTitle2 === null || drugInfoData.value.tabTitle2.length === 0)) {
              pushToast('warning', '상세 탭2 제목을 입력하세요.')
              return
            }

            if (drugInfoData.value.tabContent3 != null && (drugInfoData.value.tabTitle3 === null || drugInfoData.value.tabTitle3.length === 0)) {
              pushToast('warning', '상세 탭3 제목을 입력하세요.')
              return
            }
          }
        }

        if (!drugInfoData.value.mainImgFileId) {
          pushToast('warning', '메인 썸네일 이미지가 선택되지 않았습니다.')
          return
        }

        if (!drugInfoData.value.subImgFileId) {
          pushToast('warning', '서브 썸네일 이미지가 선택되지 않았습니다.')
          return
        }

        // topfix를 사용하지 않으면 순위는 0으로 고정
        if (!drugInfoData.value.liveInfo.isTopFix) {
          drugInfoData.value.liveInfo.topFixLevel = 0
        }

        const duplicateNoticeTimes = new Set()
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < drugInfoData.value.liveInfo.schedules.length; i++) {
          if (drugInfoData.value.liveInfo.schedules[i].noticeTime !== null) {
            const currentNoticeTime = drugInfoData.value.liveInfo.schedules[i].noticeTime.substr(0, 5)

            if (duplicateNoticeTimes.has(currentNoticeTime) && (drugInfoData.value.liveInfo.isScheduleUse || !drugInfoData.value.liveInfo.isImmediate)) {
              pushToast('danger', '스케줄,처방알림 시간에 중복이 있습니다.')
              return
            }
            duplicateNoticeTimes.add(currentNoticeTime)
          }
        }

        if (drugInfoData.value.liveInfoId) {
          updateDrugInfo()
        } else {
          saveNewDrugInfo()
        }
      },
    }

    const tableColumns = [
      {
        key: "liveInfoId", label: "ID", sortable: true, thStyle: { width: '100px' },
      },
      {
        key: "clientName", label: "제약사명", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "title", label: "제목", sortable: true,
      },
      {
        key: "isMoaView",
        label: "모아보기",
        sortable: true,
        thStyle: {
          width: '100px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px',
        },
      },
      {
        key: "isInfoPopUp",
        label: "팝업알림",
        sortable: true,
        thStyle: {
          width: '100px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px',
        },
      },
      {
        key: "isLiveDi",
        label: "라이브DI",
        sortable: true,
        thStyle: {
          width: '100px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px',
        },
      },
      {
        key: "liveDate", label: "게시기간", sortable: true, thStyle: { width: '15%' },
      },
      {
        key: "accessCode", label: "접속코드", sortable: true, thStyle: { width: '160px' },
      },
      {
        key: "liveTypeStr", label: "게시상태", sortable: true, thStyle: { width: '120px' },
      },
    ]

    const refetchData = () => {
      fetchDrugInfos()
    }

    const onRowSelectedDrugInfo = items => {
      const item = items[0]

      if (item) {
        resetDrugInfoData()
        fetchDrugInfo(item.liveInfoId)
      }
    }

    // Main Table Config End.
    const fetchDrugInfo = id => {
      axios.get(`/fa/druginfo/${id}`)
        .then(rs => {
          const { data } = rs

          data.title = data.liveInfo.title
          data.brandName = data.liveInfo.brandName
          data.isUse = data.liveInfo.isUse
          data.htmlContent = data.liveInfo.htmlContent

          drugInfoData.value = data
          drugInfoData.value.isTopFixedAllow = false

          if (rs.data.mainImgFileId) {
            fetchMainImageFile(rs.data.mainImgFileId)
          }
          if (rs.data.subImgFileId) {
            fetchSubImageFile(rs.data.subImgFileId)
          }

          tabInfoData.value.tabType1 = drugInfoData.value.tabType1 === null ? 1 : drugInfoData.value.tabType1
          tabInfoData.value.tabTitle1 = drugInfoData.value.tabTitle1

          if (drugInfoData.value.tabType1 === 1) tabInfoData.value.tabHtml1 = drugInfoData.value.tabContent1
          else if (drugInfoData.value.tabType1 === 2) tabInfoData.value.tabUrl1 = drugInfoData.value.tabContent1

          tabInfoData.value.tabType2 = drugInfoData.value.tabType2 === null ? 1 : drugInfoData.value.tabType2
          tabInfoData.value.tabTitle2 = drugInfoData.value.tabTitle2

          if (drugInfoData.value.tabType2 === 1) tabInfoData.value.tabHtml2 = drugInfoData.value.tabContent2
          else if (drugInfoData.value.tabType2 === 2) tabInfoData.value.tabHtml2 = drugInfoData.value.tabContent2

          tabInfoData.value.tabType3 = drugInfoData.value.tabType3 === null ? 1 : drugInfoData.value.tabType3
          tabInfoData.value.tabTitle3 = drugInfoData.value.tabTitle3

          if (drugInfoData.value.tabType3 === 1) tabInfoData.value.tabHtml3 = drugInfoData.value.tabContent3
          else if (drugInfoData.value.tabType3 === 2) tabInfoData.value.tabHtml3 = drugInfoData.value.tabContent3

          if (drugInfoData.value.htmlContent) {
            if (drugInfoData.value.htmlContent.includes('drinfo-rfc-visit-call')) {
              drugInfoData.value.isVisitCall = true
            }
            if (drugInfoData.value.htmlContent.includes('drinfo-rfc-sample-call')) {
              drugInfoData.value.isSampleCall = true
            }
            if (drugInfoData.value.htmlContent.includes('drinfo-rfc-email-call')) {
              drugInfoData.value.isEmailAnswer = true
            }
          }
        })
        .catch(error => {
          console.log(error)
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    // Set Codes End.
    const fetchDrugInfos = () => {
      axios
        .post("/fa/druginfo/list", {
          search: {
            query: query.value,
          },
          sort: {
            predicate: 'liveInfoId',
            reverse: true,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data
          drugInfoList.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const saveDrugInfo = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (!valid) return

        liveInfoFormValidFlag.value = !liveInfoFormValidFlag.value
      })
    }

    const saveNewDrugInfo = () => {
      if (!isProcessing.value) {
        drugInfoData.value.liveInfo.contentType = 1 // 약품정보 컨텐츠 지정
        isProcessing.value = true
        axios.put('/fa/druginfo/new', drugInfoData.value)
          .then(rs => {
            toast({
              component: ToastificationContent,
              props: {
                title: '신규 라이브인포 등록 완료',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            refetchData()

            drugInfoData.value.liveInfoId = rs.data.id
            isProcessing.value = false
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            isProcessing.value = false
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: errorFormatter('저장중 입니다. 잠시 기다려 주세요.'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    }

    const updateDrugInfo = () => {
      axios.patch('/fa/druginfo/update', drugInfoData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeDrugInfo = () => {
      if (!drugInfoData.value.liveInfoId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return
          axios.delete(`/fa/druginfo/${drugInfoData.value.liveInfoId}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetDrugInfoData()
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    const uploadMainImage = file => {
      if (!file) {
        // 파일 초기화
        return
      }
      const formData = new FormData()

      formData.append('containerName', 'druginfo')
      formData.append('file', file)

      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            drugInfoData.value.mainImgFileId = rs.data.id
            fetchMainImageFile(rs.data.id)
          }
        })
        .catch(() => {
        })
    }

    const fetchMainImageFile = id => {
      if (!id) {
        pushToast('warning', '조회할 파일이 없습니다.')

        return
      }

      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            drugInfoFiles.value.mainImgFileName = rs.data.fileName
            drugInfoFiles.value.mainImgFileSrc = rs.data.filePath
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
          pushToast('danger', '파일 조회 중 오류가 발생하였습니다.')
        })
    }

    const uploadSubImage = file => {
      if (!file) {
        // 파일 초기화
        return
      }
      const formData = new FormData()

      formData.append('containerName', 'druginfo')
      formData.append('file', file)

      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            drugInfoData.value.subImgFileId = rs.data.id
            fetchSubImageFile(rs.data.id)
          } else {
            pushToast('danger', '파일 업로드에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    const fetchSubImageFile = id => {
      if (!id) {
        pushToast('warning', '조회할 파일이 없습니다.')
        return
      }

      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            drugInfoFiles.value.subImgFileName = rs.data.fileName
            drugInfoFiles.value.subImgFileSrc = rs.data.filePath
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    const readContentFile = e => {
      if (!e) return
      if (e.type !== 'text/html') {
        pushToast('danger', '잘못된 파일 형식 입니다.')
        contentFile.value.reset()
      } else {
        const reader = new FileReader()
        reader.onload = event => {
          drugInfoData.value.htmlContent = event.target.result
        }
        reader.readAsText(e)
      }
    }

    const openEditorModal1 = () => {
      editorModal1.value = true
    }

    const openEditorModal2 = () => {
      editorModal2.value = true
    }

    const openEditorModal3 = () => {
      editorModal3.value = true
    }

    const downloadContent = () => {
      window.location.href = `/fa/druginfo/${drugInfoData.value.liveInfoId}/file`
    }

    const drugInfoTypeChange = () => {
      if (drugInfoData.value.drugInfoType === 2) { // 탭분할시
        drugInfoData.value.isVisitCall = false
        drugInfoData.value.isEmailAnswer = false
        drugInfoData.value.isSampleCall = false
      }
    }

    const tabSubmit1 = () => {
      if (tabInfoData.value.tabType1 === 2 && tabInfoData.value.tabUrl1 !== null && tabInfoData.value.tabUrl1.length > 0) {
        const pattern = /^(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

        if (pattern.test(tabInfoData.value.tabUrl1) === false) {
          pushToast('warning', '유효한 URL 형식이 아닙니다.')
          return
        }
      }

      if (tabInfoData.value.tabType1 !== null) {
        drugInfoData.value.tabType1 = tabInfoData.value.tabType1
        drugInfoData.value.tabTitle1 = tabInfoData.value.tabTitle1

        if (tabInfoData.value.tabType1 === 1) drugInfoData.value.tabContent1 = tabInfoData.value.tabHtml1
        else if (tabInfoData.value.tabType1 === 2) drugInfoData.value.tabContent1 = tabInfoData.value.tabUrl1
      }
      editorModal1.value = false
    }

    const tabSubmit2 = () => {
      if (tabInfoData.value.tabType2 === 2 && tabInfoData.value.tabUrl2 !== null && tabInfoData.value.tabUrl2.length > 0) {
        const pattern = /^(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

        if (pattern.test(tabInfoData.value.tabUrl2) === false) {
          pushToast('warning', '유효한 URL 형식이 아닙니다.')
          return
        }
      }

      if (tabInfoData.value.tabType2 !== null) {
        drugInfoData.value.tabType2 = tabInfoData.value.tabType2
        drugInfoData.value.tabTitle2 = tabInfoData.value.tabTitle2

        if (tabInfoData.value.tabType2 === 1) drugInfoData.value.tabContent2 = tabInfoData.value.tabHtml2
        else if (tabInfoData.value.tabType2 === 2) drugInfoData.value.tabContent2 = tabInfoData.value.tabUrl2
      }

      editorModal2.value = false
    }

    const tabSubmit3 = () => {
      if (tabInfoData.value.tabType3 === 2 && tabInfoData.value.tabUrl3 !== null && tabInfoData.value.tabUrl3.length > 0) {
        const pattern = /^(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

        if (pattern.test(tabInfoData.value.tabUrl3) === false) {
          pushToast('warning', '유효한 URL 형식이 아닙니다.')
          return
        }
      }

      if (tabInfoData.value.tabType3 !== null) {
        drugInfoData.value.tabType3 = tabInfoData.value.tabType3
        drugInfoData.value.tabTitle3 = tabInfoData.value.tabTitle3

        if (tabInfoData.value.tabType3 === 1) drugInfoData.value.tabContent3 = tabInfoData.value.tabHtml3
        else if (tabInfoData.value.tabType3 === 2) drugInfoData.value.tabContent3 = tabInfoData.value.tabUrl3
      }
      editorModal3.value = false
    }

    return {
      codes,
      fetchCodes,
      onRowSelectedDrugInfo,
      totalRecords,
      dateFormatter,
      tableColumns,
      refetchData,
      toast,
      resetDrugInfoData,
      fetchDrugInfo,
      fetchDrugInfos,
      query,
      drugInfoData,
      contentsFile,
      liveInfoForm,
      liveInfoFormValidFlag,
      liveInfoFormClearFlag,
      saveDrugInfo,
      updateDrugInfo,
      saveNewDrugInfo,
      drugInfoList,
      removeDrugInfo,
      uploadMainImage,
      uploadSubImage,
      drugInfoFiles,
      readContentFile,
      contentFile,
      mainImgFile,
      subImgFile,
      showModal,
      detailTabView1,
      detailTabView2,
      detailTabView3,
      editorModal1,
      editorModal2,
      editorModal3,
      openEditorModal1,
      openEditorModal2,
      openEditorModal3,
      downloadContent,
      drugInfoTypeChange,
      tabInitState,
      tabInfoData,
      tabSubmit1,
      tabSubmit2,
      tabSubmit3,
      isProcessing,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#drugInfoPreview .modal-dialog {
  max-width: 1150px;
}
</style>
